<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CModalExtended
    :title="$t('label.newWorkShiftPerVesselType')"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    class="modal-extended"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <transition name="slide-fade" mode="out-in">
        <CRow >
          <CCol sm="12">
            <CRow class="justify-content-center">
              <CCol sm="11"  lg="3"  class="text-right mt-2 pr-1">
                <label for="tpGrua"><b class="text-danger"> * </b> {{ $t('label.turn') }} </label>
              </CCol>
              <CCol sm="11"  lg="6"  class="pr-0">
                <v-select 
                  :options="turnsOptions"
                  label="label"
                  :placeholder="$t('label.select')"
                  :class="isTurnValido(turnVal)" 
                  v-model="turn"
                  :disabled="isTurnsEmpty"
                />
              </CCol>
            </CRow>
            <CRow class="justify-content-center">
              <CCol sm="11"  lg="3"  class="text-right mt-2 pr-1">
              </CCol>
              <CCol sm="11"  lg="6" >
                <div :class="isTurnValid">
                        {{$t('label.required')}}
                      </div>
              </CCol>
            </CRow>
            <CRow class="justify-content-center">
              <CCol sm="11" lg="3" class="text-right mt-2 pr-1">
                <label><b class="text-danger"> * </b> {{ $t('label.vesselType') }} </label>
              </CCol>
              <CCol sm="11" lg="6" class="pr-0">
                <v-select 
                  :options="vesselTypesOptions"
                  label="TpVesselName"
                  :placeholder="$t('label.select')"
                  class="select-adjust"
                  v-model="vesselType"
                  :disabled="isVesselTypesEmpty"
                  :class="isVesselTypeValido(vesselTypeVal)" 
                />
              </CCol>
            </CRow>
            <CRow class="justify-content-center">
              <CCol sm="11"  lg="3"  class="text-right mt-2 pr-1">
              </CCol>
              <CCol sm="11"  lg="6" >
                <div :class="isTurnValid">
                        {{$t('label.required')}}
                      </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </transition>       
    </form>
    <template #footer>
      <CButton
        color="add"
        class="d-flex align-items-center"
        :disabled="isSubmit"
        @click.stop="submit"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </CButton>
      <CButton
        color="wipe"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
</div>
</template>

<script>
import ShiftVesselTypeFormValidations  from '@/_validations/estiba/ShiftVesselType';
import UpperCase  from '@/_validations/uppercase-directive';
import { ShiftVesselTypeMixin } from '@/_mixins/estiba';
import  ModalMixin  from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoading: false,
    isLoadingTurns: false,
    turn: null, 
    turnsList: [],
    vesselType: null,
    vesselTypesList: [], 

    Status: null, 
   
    turnVal: false, 
    vesselTypeVal: false,
  }
}

//Methods
function submit() {
  try {
    this.isLoadingGeneral = true;
    this.isSubmit = true;
    this.turnVal = true;
    this.vesselTypeVal = true;
    this.$v.$touch();

    if (this.$v.$error) {
      this.isLoadingGeneral = false;
      this.isSubmit = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let TpVesselTurnJson = {
      TurnId: this.turn.TurnId,
      TpVesselId: this.vesselType.TpVesselId,
    }

    this.$http.post("TpVesselTurn-insert", TpVesselTurnJson, { root: 'TpVesselTurnJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.isLoadingGeneral = false;
    }); 
  } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }
}

function toggle(newVal) {
  if(newVal)
  {
    this.resetForm();
    this.loadDataShiftVesselType(); 
  } 
  else
  {
    this.resetForm();
    this.$emit('submited');
  }

  this.modalActive = newVal;
}

function resetForm() {
  this.turn = null;
  this.turnsList = [];
  this.vesselType = null;
  this.vesselTypesList = [];
  this.Status= null;
  this.turnVal= false;
  this.vesselTypeVal= false;
  this.$v.$reset();
}

function isTurnValido(turnVal) {
  if(this.turnVal === true){ 
    return (this.turn == null || this.turn == "") ? 'no-valido' : 'valido';
  }
}

function isVesselTypeValido(vesselTypeVal) {
  if(this.vesselTypeVal === true){ 
    return (this.vesselType == null || this.vesselType == "") ? 'no-valido' : 'valido';
  }
}

function getTurns() {
  this.isLoadingTurns = true;
  let _this = this.$i18n.locale;

  this.$http.get("Turn-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.turnsList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoadingTurns = false;
  });
}



function getVesselTypeList() {
  this.isLoading = true;

  this.$http.get("TpVessel-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.vesselTypesList = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}
//Computeds:
function vesselTypesOptions() {
   return this.vesselTypesList.map((item) => Object.assign({}, item, {
    value: item.TpVesselId,
    TpVesselName: item.TpVesselName,
  }));
}

function turnsOptions() {  
  let _this = this.$i18n.locale;

  return this.turnsList.map((item) => Object.assign({}, item, {
    value: item.TurnId,
    label: _this == 'en' ? item.TurnDsEn : item.TurnDs,
  }));
}




export default {
  name: 'modal-create-shift-vessel-type',
  mixins: [
    ShiftVesselTypeMixin,
    ModalMixin
  ],
  props: {
    modal: Boolean
  },
  components: {
  },
  data,
  validations: ShiftVesselTypeFormValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    isTurnValido,
    isVesselTypeValido,
    getVesselTypeList,
    getTurns,
  },
  computed: {
    vesselTypesOptions,
    turnsOptions,
  },
  watch:{
    modal: function(val) {
      if(val){
        this.getTurns();
        this.getVesselTypeList();
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.center-cell-embalaje {
  text-align: center;
}
.invalido {
  display: block;
}
/*
td {
  vertical-align: middle !important;
}
*/
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}

.select-adjust {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.center-cell {
text-align: center;
}
 
</style>